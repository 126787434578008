import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import BoutonContinuer from "../../boutons/BoutonContinuer"
import Image from "../../image/Image"
import Lien from "../../navigation/Lien"

import { obtenirImage, obtenirImages } from "../../../js/client-es/utils"
import { locales } from "../../../../locales"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "astrophotographieavecUnReflexExtrait4"
            "astrophotographieavecUnReflexExtrait3"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitMoyen
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["astrophotographieavecUnReflex", "megaPhone"] } }
    ) {
      ...FragmentImageLivreElectronique
    }
  }
`

export default function LivreElectroniqueCallToAction({ page }) {
  const resultatsRequete = useStaticQuery(requete)
  let images = obtenirImages(
    resultatsRequete,
    "imagesPortraitMoyen",
    "imagesPortrait"
  )

  return (
    <section
      id="astrophotographie-reflex-conteneur"
      className="section-conteneur"
    >
      <h2 className="section-titre">
        Astrophotographie avec un réflex (ou un hybride…){" "}
      </h2>
      <div className="section-conteneur">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <p>
              Pour vous aider à débuter ou progresser en astrophotographie avec
              votre réflex ou votre hybride, j’ai réuni en un livre électronique
              de <strong>215 pages</strong> des techniques issues de plus de 15
              années de pratique de la discipline.
            </p>
            <p>
              <strong>📚 Au sommaire :</strong>
            </p>
            <ul>
              <li>
                Pour chaque thème, des expériences terrain et un tutoriel
                détaillé de traitement avec Photoshop.
              </li>
              <li>Voie lactée et arches de Voie lactée.</li>
              <li>Aurores boréales.</li>
              <li>Pluies d’étoiles filantes.</li>
              <li>
                Ciel profond (nébuleuses, galaxies…) <strong>(43 pages)</strong>
                .
              </li>
              <li>Lune et comètes.</li>
              <li>Visites virtuelles.</li>
              <li>Imprimer ses astrophotographies.</li>
              <li>…</li>
            </ul>
            <strong>🎉 Mise à jour du 09/02/2025 (1.8)</strong> : conseils pour
            un séjour astrophotographique réussi (Annexes), mise à jour des
            objectifs et des boîtiers (Chap. « Guide d'achat »), ...
          </div>
          <div>
            <Lien codePageCible="astrophotographieReflex">
              <Image
                image={obtenirImage(images, "astrophotographieavecUnReflex")}
                afficherLegende={false}
                afficherOmbre={false}
                langue={page.langue}
              />
            </Lien>
          </div>
        </div>
      </div>
      <div className="conteneur-centre">
        <BoutonContinuer
          texte={locales[page.langue].site.plusDInformations}
          codePageCible="astrophotographieReflex"
          classeCSS="bouton bouton-continuer"
        />
      </div>
      <div className="boutique-conteneur-images">
        <Lien
          codePageCible="astrophotographieReflex"
          classeCSS="lien-sans-effet"
        >
          <Image
            image={obtenirImage(
              images,
              "astrophotographieavecUnReflexExtrait4"
            )}
            afficherLegende={false}
            afficherOmbre={false}
            langue={page.langue}
          />
        </Lien>
        <Lien
          codePageCible="astrophotographieReflex"
          classeCSS="lien-sans-effet"
        >
          <Image
            image={obtenirImage(
              images,
              "astrophotographieavecUnReflexExtrait3"
            )}
            afficherLegende={false}
            afficherOmbre={false}
            langue={page.langue}
          />
        </Lien>
      </div>
    </section>
  )
}

LivreElectroniqueCallToAction.propTypes = {
  page: PropTypes.object,
}
